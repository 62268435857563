import { useMutation } from '@apollo/client';
import Linkify from 'react-linkify';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import { loadStripe } from '@stripe/stripe-js/pure';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import useCurrentUser from '../hooks/useCurrentUser';
import Header from './BasicHeader';
import Footer from './Footer';
import Layout from './Layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    avatar: {
      marginBottom: theme.spacing(1),
      width: '3rem',
      height: '3rem',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      whiteSpace: 'pre-line',
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    accept: {
      padding: theme.spacing(0.5),
    },
  })
);

const CHECKOUT_CREATION = gql`
  mutation CreateCheckout($input: TrackCheckoutInput!) {
    checkout: trackPriceCheckout(input: $input) {
      sessionId
      accountId
      stripeKey
    }
  }
`;

const steps = ['C2 Login', 'Payment', 'Confirmation'];

const RECURRING = { monthly: 'per month' };

const TrackPage = ({ pageContext }) => {
  const { track } = pageContext;
  const price = track.prices[0];
  const [stripeSuccess] = useQueryParam('stripeSuccess', StringParam);
  const [tokenParam, setTokenParam] = useQueryParam('token', StringParam);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { currentUser, redirectToAuth, savePersistedToken } = useCurrentUser();

  const [createCheckout] = useMutation(CHECKOUT_CREATION, {});

  const membership =
    currentUser &&
    currentUser.trackMemberships.find(
      (tm) => tm.track.id === track.id && tm.active && !tm.trial
    );

  const styles = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const checkout = () => {
    const url = window.location.href;
    createCheckout({
      variables: {
        input: {
          priceId: price.id,
          successUrl: `${url}?stripeSuccess=true`,
          cancelUrl: url,
        },
      },
    }).then(({ data }) => {
      if (data) {
        loadStripe(data.checkout.stripeKey, {
          stripeAccount: data.checkout.accountId,
        }).then((stripe) => {
          stripe.redirectToCheckout({ sessionId: data.checkout.sessionId });
        });
      }
    });
  };

  useEffect(() => {
    if (tokenParam) {
      setTokenParam(undefined, 'replace');
      savePersistedToken(tokenParam);
    }
  }, [tokenParam]);

  useEffect(() => {
    if (membership || stripeSuccess === 'true') {
      setActiveStep(2);
    } else if (currentUser) {
      setActiveStep(1);
    }
  }, [currentUser, setActiveStep, stripeSuccess, membership]);

  const allowRegistration = [
    undefined,
    null,
    'unlisted',
    'registration',
  ].includes(track.compStatus);

  return (
    <Layout>
      <Header />

      <section>
        <div className="container">
          <div className="section-heading text-center">
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar className={styles.avatar} src={track.source.imageUrl} />
              <Typography variant="h4">{price.name}</Typography>
              <Typography variant="subtitle1">
                ${(price.amountCents / 100).toFixed(2)}{' '}
                {RECURRING[price.recurring] || ''}
              </Typography>
            </Box>
            <div className={styles.root}>
              {allowRegistration ? (
                <>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, i) => (
                      <Step
                        key={label}
                        completed={
                          i < activeStep ||
                          (activeStep === steps.length - 1 && i === activeStep)
                        }
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {activeStep === 0 && (
                      <div>
                        <Typography className={styles.instructions}>
                          Login with your Concept2 Logbook account for a
                          seamless registration. If you don't have a C2 account,
                          please create one from{' '}
                          <a
                            href="https://log.concept2.com/signup"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            here
                          </a>
                          .
                        </Typography>
                        <Box>
                          <FormControlLabel
                            className={styles.accept}
                            control={
                              <Checkbox
                                checked={termsAccepted}
                                onChange={() =>
                                  setTermsAccepted((value) => !value)
                                }
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={
                              <Typography variant="body2" align="left">
                                I have read and accept ErgZone's{' '}
                                <a href="/privacy">privacy policy</a> and{' '}
                                <a href="/terms">terms</a>.
                              </Typography>
                            }
                          />
                        </Box>
                        <Button
                          className={styles.button}
                          disabled={!termsAccepted}
                          variant="contained"
                          color="primary"
                          onClick={() => redirectToAuth()}
                        >
                          Concept2 Login
                        </Button>
                      </div>
                    )}
                    {activeStep === 1 && (
                      <div>
                        <Typography className={styles.instructions}>
                          {currentUser ? `Hi ${currentUser.name}, we ` : 'We '}
                          integrate with Stripe: one of the most secure payment
                          platforms.
                        </Typography>
                        <Typography className={styles.instructions}>
                          Press the button below to pay with Apple Pay, Google
                          Pay or credit card.
                        </Typography>
                        <Button
                          className={styles.button}
                          variant="contained"
                          color="primary"
                          onClick={() => checkout()}
                        >
                          Stripe Checkout
                        </Button>
                      </div>
                    )}
                    {activeStep === 2 && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Alert severity="success">
                          Congratulations
                          {currentUser ? ` ${currentUser.name}` : ''}. You are
                          signed up for <strong>{track.name}</strong>! Open the
                          ErgZone app to see the new track.
                        </Alert>
                        {membership && !!membership.description && (
                          <>
                            <Typography
                              variant="h6"
                              className={styles.instructions}
                            >
                              More Information
                            </Typography>
                            <Typography className={styles.instructions}>
                              <Linkify>{membership.description}</Linkify>
                            </Typography>
                          </>
                        )}
                      </Box>
                    )}
                  </div>
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  marginTop={2}
                >
                  <Alert severity="warning">
                    Registration is closed! Open the app to see the leaderboard.
                  </Alert>
                </Box>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TrackPage;
